import { castDraft } from 'immer';
import React, { createContext, FC, ReactNode, useReducer } from 'react';
import { appReducer, ToolsState, DispatchTools, toolInitialState } from './reducer';

export interface ToolsProviderInterface {
  children: ReactNode;
  testStateProps?: Partial<ToolsState>;
  testDispatch?: DispatchTools;
}

const ToolsStateContext = createContext<ToolsState | undefined>(undefined);
const ToolsDispatchContext = createContext<DispatchTools | undefined>(undefined);

const ToolsProvider: FC<ToolsProviderInterface> = ({ children, testStateProps, testDispatch }: ToolsProviderInterface) => {
  const [state, dispatch] = useReducer(appReducer, toolInitialState);

  return (
    <ToolsStateContext.Provider value={{ ...castDraft(state), ...testStateProps }}>
      <ToolsDispatchContext.Provider value={testDispatch || dispatch}>{children}</ToolsDispatchContext.Provider>
    </ToolsStateContext.Provider>
  );
};

const useToolsState = (): ToolsState => {
  const context = React.useContext(ToolsStateContext) as ToolsState;

  if (context === undefined) {
    throw new Error('useToolsState must be used within a ToolsStateContext');
  }

  return context;
};

const useToolsDispatch = (): DispatchTools => {
  const context = React.useContext(ToolsDispatchContext) as DispatchTools;

  if (context === undefined) {
    throw new Error('useDispatchToolsDispatch must be used within a ToolsDispatchContext');
  }

  return context;
};

export { ToolsProvider, useToolsState, useToolsDispatch };
