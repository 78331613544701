export const WRSConfigInitialState = {
  isCustom: false,
  matchId: '',
  teamAname: '',
  teamAlogo: '',
  teamAscore: 0,
  teamBname: '',
  teamBlogo: '',
  teamBscore: 0
}

export const WildriftToolPlayerObjectInitialState = {
  player1: null,
  player2: null,
  player3: null,
  player4: null,
  player5: null
}

export const WildriftToolBanObjectInitialState = {
  ban1: null,
  ban2: null,
  ban3: null,
  ban4: null,
  ban5: null
}

export const WildriftToolPickObjectInitialState = {
  pick1: null,
  pick2: null,
  pick3: null,
  pick4: null,
  pick5: null
}

export const WildRiftToolInitialState = {
  _id: '',
  userID: '',
  settings: {
    banNumber: 5,
    sideInverse: false,
    startPick: false,
    showOverlay: false,
    showSponsorInScore: false,
    showObjetiveBanner: false,
    color: '00E1FF',
    pickTheme: 'BOTTOM',
    objetiveBanner: 'BOTTOM',
    mainLogo: '',
  },
  sponsors: [],
  teamBlue: {
    name: '',
    logo: '',
    score: 0,
    color: '2d98df',
    players: WildriftToolPlayerObjectInitialState,
    bans: WildriftToolBanObjectInitialState,
    picks: WildriftToolPickObjectInitialState,
  },
  teamRed: {
    name: '',
    logo: '',
    score: 0,
    color: 'bf2b2b',
    players: WildriftToolPlayerObjectInitialState,
    bans: WildriftToolBanObjectInitialState,
    picks: WildriftToolPickObjectInitialState,
  }
}

export const CommunitySpotInitialState = {
  _id: '',
  userID: '',
  hashtag: '#APGstats',
  settings: {
    fontSizeName: '24',
    fontSizeText: '16',
    fontColor: '#000000',
    fontTagsColor: '#CCCCCC',
    fontFamily: 'Open Sans',
    fontVariant: 'regular',
    queuedTimer: 15,
    containerWidth: 'sm'
  },
  tweetDisplayed: {
    id: '',
    tweet: '',
    createdAt: '',
    name: '',
    username: ''
  },
  sendedTweets: [],
  queued: []
}

export const ValorantToolMapObjectInitialState = {
  map1: {
    uuid: ''
  },
  map2: {
    uuid: ''
  },
  map3: {
    uuid: ''
  },
  map4: {
    uuid: ''
  },
  map5: {
    uuid: ''
  },
  map6: {
    uuid: ''
  },
  map7: {
    uuid: ''
  }
}

export const ValorantToolPickObjectInitialState = {
  pick1: '',
  pick2: '',
  pick3: '',
  pick4: '',
  pick5: ''
}

export const ValorantToolPlayerObjectInitialState = {
  player1: '',
  player2: '',
  player3: '',
  player4: '',
  player5: ''
}

export const ValorantToolInitialState = {
  _id: '',
  userID: '',
  settings: {
    sideInverse: false,
    matchType: 'BO1',
    color: '#00E1FF',
    inGameTheme: 'TOP',
    pickTheme: 'SIDE',
    mapTheme: 'BOTTOM',
    mapCustomTheme: '',
    mainLogo: '',
  },
  teamBlue: {
    name: '',
    logo: '',
    score: 0,
    players: ValorantToolPlayerObjectInitialState,
    picks: ValorantToolPickObjectInitialState,
  },
  teamRed: {
    name: '',
    logo: '',
    score: 0,
    players: ValorantToolPlayerObjectInitialState,
    picks: ValorantToolPickObjectInitialState,
  },
  maps: ValorantToolMapObjectInitialState
}