import React, { FC, useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

const Home = React.lazy(() => import('./app/Home/Home'));
const Tools = React.lazy(() => import('./app/Tools/Tools'));
const Views = React.lazy(() => import('./app/Views/Views'));

import './App.scss';
import { useGlobalAppDispatch, useGlobalAppState } from './store/app-context';
import { AppActions } from './store/reducer';
import { Spin, BackTop } from 'antd';
import { ToolsProvider } from './app/Tools/store/tool-context';
import { useCookies } from 'react-cookie';
import { Palette } from '@apg-stats/apg-core/lib/css-in-js';
import Layout from 'antd/lib/layout/layout';
import { Token, TokenUser } from './domain/token.interface';
import ApgWrapper from './components/ApgWrapper/ApgWrapper';

const App: FC = () => {
  const { pendingFetches } = useGlobalAppState();
  const dispatchApp = useGlobalAppDispatch();
  const [cookies] = useCookies();
  const [isReady, setIsReady] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState<TokenUser>();

  const history = useHistory();
  const goToUrl = (path: string): void => {
    history.push(path);
  }

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const menuItems =[
    {
      id: '',
      title: 'APG+',
      href: '/'
    },
    {
      id: 'wild-rift',
      title: 'Wild Rift',
      href: '/tools/wild-rift'
    },
    {
      id: 'valorant',
      title: 'Valorant',
      href: '/tools/valorant'
    },
    {
      id: 'community-spot',
      title: 'Community Spot',
      href: '/tools/community-spot'
    }
  ];

  useEffect(() => {
    if (cookies.APGTOKEN) {
      const decodeToken: Token = jwt_decode(cookies.APGTOKEN);

      dispatchApp({ type: AppActions.SetUserToken, payload: decodeToken.user })
      setUser(decodeToken.user)
      setIsLogged(true)
    }

    setIsReady(true);
  }, [cookies]);

  if (!isReady) {
    return null;
  }

  return (
    <>
      <BackTop />
      <Switch>
        <Route exact path="/">
          <Layout style={{ backgroundColor: Palette.Gray100 }}>
            {pendingFetches > 0 && (
              <div className="loading-container">
                <Spin />
              </div>
            )}
            <ApgWrapper
              active={splitLocation[1]}
              menuItems={menuItems}
              goToUrl={goToUrl}
              isLogged={isLogged}
              user={user}
            >
              <Home />
            </ApgWrapper>
          </Layout>
        </Route>
        <Route path="/tools">
          <Layout style={{ backgroundColor: Palette.Gray100 }}>
            {pendingFetches > 0 && (
              <div className="loading-container">
                <Spin />
              </div>
            )}
            <ToolsProvider>
              <ApgWrapper
                active={splitLocation[1]}
                menuItems={menuItems}
                goToUrl={goToUrl}
                isLogged={isLogged}
                user={user}
              >
                <Tools />
              </ApgWrapper>
            </ToolsProvider>
          </Layout>
        </Route>
        <Route path="/views/:id">
          <Views />
        </Route>
        <Redirect from="*" to="/" />
      </Switch>
    </>
    
  )
};

export default App;
