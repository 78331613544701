import { FC, ReactNode } from 'react';
import ApgToolbar from '@apg-stats/apg-core/lib/ApgToolbar';
import Layout from 'antd/lib/layout';

export interface AppToolbarItemInterface {
  id: string,
  title: string,
  href: string,
  disabled?: boolean
}

export interface AppToolbarUserInterface {
  _id: string,
  username: string,
  name: string,
  avatar: string,
  role: string,
}

export interface ApgWrapperInterface {
  children: ReactNode;
  active: string;
  isLogged: boolean;
  user?: AppToolbarUserInterface;
  menuItems?: AppToolbarItemInterface[];
  goToUrl: (path: string) => void;
}

const ApgWrapper: FC<ApgWrapperInterface> = ({ 
  children,
  active,
  isLogged,
  user,
  menuItems,
  goToUrl,
}): JSX.Element => {
  return (
    <>
      <ApgToolbar
        active={active}
        showGameLogo={false}
        menuItems={menuItems}
        goToUrl={goToUrl}
        showSecondaryMenu={false}
        isLogged={isLogged}
        user={user}
        accountUrl={process.env.REACT_APP_APG_ACCOUNT}
      />
      <Layout style={{ marginTop: '4rem', minHeight: 'calc(100vh - 4rem)' }}>
        {children}
      </Layout>
    </>
  )
};

export default ApgWrapper;
