import produce, { Draft } from 'immer';
import { Dispatch } from 'react';
import { DispatchObject } from '../../../domain/dispatch-object';
import { CommunitySpotConfig, WildRiftTool } from '../domain/interfaces';
import { WildRiftToolInitialState, CommunitySpotInitialState, ValorantToolInitialState } from '../domain/states';
import { ValorantTool } from '../domain/valorant.interface';

export type DispatchTools = Dispatch<DispatchObject<ToolsActions>>;

export enum ToolsActions {
  SetTool = 'SET_TOOL',
  SetWildRiftTool = 'SET_WILDRIFT_TOOL',
  UpdateWildRiftTool = 'UPDATE_WILDRIFT_TOOL',
  SetValorantTool = 'SET_VALORANT_TOOL',
  UpdateValorantTool = 'UPDATE_VALORANT_TOOL',
  SetCommunitySpotTool = 'SET_COMMUNITY_SPOT_TOOL',
  UpdateCommunitySpotTool = 'UPDATE_COMMUNITY_SPOT_TOOL',
  ClearData = 'CLEAR_DATA'
}

export interface ToolsState {
  wildRiftTool: WildRiftTool | null;
  communitySpot: CommunitySpotConfig | null;
  valorant: ValorantTool | null;
  error: null;
}

export const toolInitialState: ToolsState = {
  wildRiftTool: WildRiftToolInitialState || null,
  communitySpot: CommunitySpotInitialState || null,
  valorant: ValorantToolInitialState || null,
  error: null,
};

export const appReducer = produce((draft: Draft<ToolsState>, { type, payload }: DispatchObject<ToolsActions>) => {
  switch (type) {
    case ToolsActions.SetWildRiftTool:
      draft.wildRiftTool = payload;
      break;
    case ToolsActions.UpdateWildRiftTool:
      draft.wildRiftTool = payload;
      break;
    case ToolsActions.SetValorantTool:
      draft.valorant = payload;
      break;
    case ToolsActions.UpdateValorantTool:
      draft.valorant = payload;
      break;
    case ToolsActions.SetCommunitySpotTool:
      draft.communitySpot = payload;
      break;
    case ToolsActions.UpdateCommunitySpotTool:
      draft.communitySpot = payload;
      break;
    case ToolsActions.ClearData:
      return toolInitialState;
    default:
      throw new Error(`Unknown global action type: ${type}`);
  }
});
